<template>
  <v-container fluid class="result-container pa-4 pa-sm-6 pa-md-8">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="8" xl="6">
        <v-card elevation="2">
          <v-card-title class="headline">投票结果</v-card-title>
          <v-list>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-content>
                <v-list-item-title>
                  <a :href="item.link" target="_blank" class="result-link">{{ item.title }}</a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: "Result",
    data() {
        return {
          items: [
            { title: '三国演义武评结果', link: 'https://www.zhihu.com/question/521940059/answer/3264616239' },
            { title: '三国演义智评结果', link: 'https://www.zhihu.com/question/372483549/answer/3421676289' },
            { title: '三国演义武将智评结果', link: 'https://www.zhihu.com/question/556209627/answer/3339697787' },
            { title: '水浒传武评结果', link: 'https://www.zhihu.com/question/21441647/answer/3511359686' }
          ]
        }
    },
    created() {
    },
    methods: {
    }
};
</script>

<style scoped>
.result-container {
  background-color: #f5f5f5;
  min-height: calc(100vh - 100px); /* 调整为顶部导航栏和页脚的总高度 */
}

.result-link {
  text-decoration: none;
  color: #1e88e5;
  transition: color 0.3s ease;
}

.result-link:hover {
  color: #1565c0;
}
</style>
